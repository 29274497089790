export default {
  start_date: '到达',
  end_date: '离开',
  guests: '客人',
  adults: '成人',
  children: '儿童',
  submit: '检查可用性',
  book_now: '立即预订',
  ok: '确定',
  cancel: '取消',
  destination: '目的地',
}