export default {
  start_date: 'Ankomst',
  end_date: 'Afrejse',
  guests: 'Gæster',
  adults: 'Voksne',
  children: 'Børn',
  submit: 'Tjek tilgængelighed',
  book_now: 'Book nu',
  ok: 'OK',
  cancel: 'Annuller',
  destination: 'Destination',
}
