export default {
  start_date: 'j\'arrive',
  end_date: 'Départ',
  guests: 'Invités',
  adults: 'Adultes',
  children: 'Enfants',
  submit: 'Voir les disponibilités',
  book_now: 'Reserve maintenant',
  ok: 'OK',
  cancel: 'Annuler',
  destination: 'Destination',
}
