export default {
  start_date: 'Arribada',
  end_date: 'Sortida',
  guests: 'Convidats',
  adults: 'Adults',
  children: 'Nens',
  submit: 'Comprova Disponibilitat',
  book_now: 'Reserva Ara',
  ok: 'D\'acord',
  cancel: 'Cancel·la',
  destination: 'Destinació',
}
