export default {
  start_date: 'Aankomst',
  end_date: 'Vertrek',
  guests: 'Gasten',
  adults: 'Volwassenen',
  children: 'Kinderen',
  submit: 'Beschikbaarheid controleren',
  book_now: 'Nu boeken',
  ok: 'OK',
  cancel: 'Annuleren',
  destination: 'Bestemming',
}
