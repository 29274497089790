export default {
  start_date: 'Arrivo',
  end_date: 'Partenza',
  guests: 'Ospiti',
  adults: 'Adulti',
  children: 'Bambini',
  submit: 'Controlla Disponibilità',
  book_now: 'Prenota Adesso',
  ok: 'OK',
  cancel: 'Annulla',
  destination: 'Destinazione',
}