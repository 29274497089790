export default {
  start_date: 'Anreise',
  end_date: 'Abreise',
  guests: 'Gäste',
  adults: 'Erwachsene',
  children: 'Kinder',
  submit: 'Verfügbarkeit prüfen',
  book_now: 'Buchen Sie jetzt',
  ok: 'OK',
  cancel: 'Abbrechen',
  destination: 'Reiseziel',
}