export default {
  start_date: 'Arrival',
  end_date: 'Departure',
  guests: 'Guests',
  adults: 'Adults',
  children: 'Children',
  submit: 'Check Availability',
  book_now: 'Book now',
  ok: 'OK',
  cancel: 'Cancel',
  destination: 'Destination',
}
