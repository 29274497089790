export default {
  start_date: 'Dolazak',
  end_date: 'Odlazak',
  guests: 'Gosti',
  adults: 'Odrasli',
  children: 'Djeca',
  submit: 'Provjeri raspoloživost',
  book_now: 'Rezerviraj sada',
  ok: 'U redu',
  cancel: 'Otkaži',
  destination: 'Odredište',
}
