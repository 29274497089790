export default {
  start_date: 'Check-in',
  end_date: 'Check-out',
  guests: 'Huéspedes',
  adults: 'Adultos',
  children: 'Niños',
  submit: 'Consultar disponibilidad',
  book_now: 'Reservar ahora',
  ok: 'OK',
  cancel: 'Annuler',
  destination: 'Destino',
}
