export default {
  start_date: 'Chegada',
  end_date: 'Partida',
  guests: 'Hóspedes',
  adults: 'Adultos',
  children: 'Crianças',
  submit: 'Verificar Disponibilidade',
  book_now: 'Reserve Agora',
  ok: 'OK',
  cancel: 'Cancelar',
  destination: 'Destino',
}