export default {
  start_date: 'Прибытие',
  end_date: 'Отъезд',
  guests: 'Гости',
  adults: 'Взрослые',
  children: 'Дети',
  submit: 'Проверить наличие',
  book_now: 'Забронировать сейчас',
  ok: 'ОК',
  cancel: 'Отмена',
  destination: 'Назначение',
}
